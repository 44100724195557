import React from "react";
import { Link } from "gatsby";
import causeImage1 from "../../images/jfk.png";
import TitleSidebar from "./TitleSidebar";

const CausesDetail = () => {
    return (
        <section className="causes-detail-area">
            <div className="container">
                <div className="row blog-content-wrap">
                    <div className="col-lg-8">
                        <div className="blog-content">
                            <div className="blog-item">
                                <div className="blog-img">
                                    <img src={causeImage1} alt="" />
                                </div>
                                <div className="blog-inner-content">
                                    <div className="inner-causes-box">
                                        <h3 className="blog__title">
                                            <Link to="/causes-detail">Jaycee Foundation Crown</Link>
                                        </h3>
                                        <ul className="blog__list">
                                            <li>
                                                <i className="icon-heart"></i> Amount:{" "}
                                                <span> <i className="fa fa-inr"></i> 75,00,000</span>
                                            </li>
                                            {/*
                                                <li>
                                                    <i className="fa fa-line-chart"></i> Raised:{" "}
                                                    <span>25,270</span>
                                                </li>
                                            */}
                                        </ul>
                                    </div>
                                    <div className="inner-causes-btn">
                                        <Link to="/donate" className="theme-btn">
                                            donate now
                                        </Link>
                                    </div>
                                </div>
                                <div className="blog-inner-content-2 text-justify">
                                    <p className="causes__text">
                                    Jaycee Foundation Crown is the title of Honor awarded to any member of JCI India or any individual or organization who believes in the principles and policies of Junior Chamber movement and who donates an amount not less than Rs. 75,00,000/- will be conferred with Honor as Jaycee foundation Kohinoor. This title was introduced in 2024 in respect of the Diamond Jubilee year of JCI India i.e. 75th year of JCI India.

                                    </p>
                                    <p className="causes__text">
                                    Seventy five needy students will get Scholarships in his/her name for 10 years, Attractive Golden Plated Pin with Diamond for donor , Name badge with attractive Diamond placed for donor, Golden plated Plaque for donor, Complimentary JCI Senatorship – special life membership of JCI , First year couple VIP registration at NATCON with airport drop and pickup , 3 years special invitee for Foundation board,VIP seating at NATCON or any invited JCI India Function. Donor’s photo gets place at the wall of Honor at NHQ 

                                    </p>
                                    <p className="causes__text">
                                    Seventy five students will get Scholarships in his/her name for 5 years, Attractive Golden Plated Pin with Diamond , Name badge with attractive Diamond placed, Golden plated Plaque , Company name & logo will be promoted in all National events throughout the donation year , Patron Registration for NATCON two years (year of donation and next consecutive year) , One complimentary registration for the CEO ( Officer selected by company) in our leadership program Nalanda or ABLE, Invitation to company representative as guest speaker or panelist at high-profile events or conferences , Donor’s photo gets place at the wall of honor at NHQ 
                                    </p>
                                    <p className="causes__text">
                                    Also , Life Time couple VIP status ( NATCON seating next to PNP’s , Entry to all VIP parties), Foundation Dinner and Lunch. For both Individual donor and for Company/Organization donor.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="sidebar-shared">
                            <TitleSidebar />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CausesDetail;
